import React, {useEffect, useRef, useState} from 'react';

//components

//styles
import * as s from './horizontal-scroll-wrapper.module.scss';


const HorizontalScrollWrapper = ({children, classes, wrapper_ul}) => {

	const [visibleLeft, setVisibleLeft] = useState(false);
	const [visibleRight, setVisibleRight] = useState(false);

	const wrapperRef = useRef(null);

	//onLoad display arrows
	useEffect(() => {
		if(wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth){
			setVisibleRight(true);
		}
	}, [wrapperRef])

	//debounce maybe
	//show and hide arrows depending on scroll position
	const handleScroll = (e) => {
		const x = e.currentTarget.scrollLeft;
		const maxX = e.currentTarget.scrollWidth - e.currentTarget.offsetWidth;

		//hide/show left arrow
		x < 10 ? setVisibleLeft(false) : setVisibleLeft(true);

		//hide/show right arrow
		x > maxX - 10 ? setVisibleRight(false) : setVisibleRight(true);
	}


	const handleScrollClick = (direction) => {
		const target = wrapperRef.current;
		const currX = target.scrollLeft;
		let leftScroll = direction === 'left' ? -100 : 100;

		target.scroll({
			left: currX + leftScroll,
			top: 0,
			behavior: 'smooth'
		});
	}


	return (
		<div className={s.outer_wrapper}>
			{visibleLeft &&
				<button className={`${s.arrow} ${s.left_arrow}`}
					onClick={() => handleScrollClick('left')} >
				</button>
			}

			{wrapper_ul
				?
					<ul className={`${classes} ${s.wrapper}`}
					    ref={wrapperRef}
					    onScroll={(e) => handleScroll(e)}
					>
						{children}
					</ul>
				:
					<div className={`${classes} ${s.wrapper}`}
					     ref={wrapperRef}
					     onScroll={(e) => handleScroll(e)}
					>
						{children}
					</div>
			}

			{visibleRight &&
				<button className={`${s.arrow} ${s.right_arrow}`}
				        onClick={() => handleScrollClick('right')} >
				</button>
			}
		</div>
	);
}

export default HorizontalScrollWrapper;
