// extracted by mini-css-extract-plugin
export var calculator = "car-calculator-module--calculator--02545";
export var calculator_section = "car-calculator-module--calculator_section--3e3a6";
export var cta = "car-calculator-module--cta--048b8";
export var field = "car-calculator-module--field--da7a3";
export var info = "car-calculator-module--info--54a33";
export var no_data = "car-calculator-module--no_data--786b9";
export var options_wrapper = "car-calculator-module--options_wrapper--f45dc";
export var price = "car-calculator-module--price--9b863";
export var result = "car-calculator-module--result--bed31";
export var result_text = "car-calculator-module--result_text--c587b";