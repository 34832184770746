// extracted by mini-css-extract-plugin
export var calculator = "single-vehicle-module--calculator--301ac";
export var calculator_section = "single-vehicle-module--calculator_section--7e488";
export var car_section = "single-vehicle-module--car_section--0ec53";
export var close = "single-vehicle-module--close--38abb";
export var cta = "single-vehicle-module--cta--26b30";
export var curr_img = "single-vehicle-module--curr_img--10841";
export var equipment_data = "single-vehicle-module--equipment_data--0c66c";
export var field = "single-vehicle-module--field--4d102";
export var form = "single-vehicle-module--form--9d715";
export var img_wrapper = "single-vehicle-module--img_wrapper--b1a11";
export var info = "single-vehicle-module--info--96aff";
export var left_wrapper = "single-vehicle-module--left_wrapper--16e16";
export var list_fold = "single-vehicle-module--list_fold--0bb9e";
export var list_wrapper = "single-vehicle-module--list_wrapper--bb451";
export var more_options = "single-vehicle-module--more_options--5373c";
export var no_data = "single-vehicle-module--no_data--c897b";
export var options_wrapper = "single-vehicle-module--options_wrapper--c4b7f";
export var price = "single-vehicle-module--price--b3fb9";
export var result = "single-vehicle-module--result--bd036";
export var result_text = "single-vehicle-module--result_text--0e227";
export var right_wrapper = "single-vehicle-module--right_wrapper--fb451";
export var shadow = "single-vehicle-module--shadow--a2007";
export var show_more = "single-vehicle-module--show_more--9428e";
export var slide = "single-vehicle-module--slide--bd7fd";
export var slide_outer = "single-vehicle-module--slide_outer--ed54f";
export var slider_wrapper = "single-vehicle-module--slider_wrapper--322b7";
export var technical_data = "single-vehicle-module--technical_data--f773d";
export var ul_flex = "single-vehicle-module--ul_flex--a5f6d";
export var wrapper_popup = "single-vehicle-module--wrapper_popup--9ba6c";