import React, { useState, useEffect } from 'react'
import * as s from './car-calculator.module.scss'
import * as s_wrapper from '@assets/styles/wrapper.module.scss'
import * as s_text from '@assets/styles/text.module.scss'
import * as s_btns from '@assets/styles/btns.module.scss'
import HorizontalScrollWrapper from '@ui-components/horizontal-scroll-wrapper'
import CarCalculatorPopupCTA from './car-calculator-popup-cta'
import axios from 'axios'

// available values
const kilometers_limits = [10000, 15000, 20000, 30000, 40000, 50000]
const initial_payments_vehis = [0, 5, 15, 25, 35, 45]
const initial_payments_starfleet = [0, 10, 20, 30, 40]
const financing_period_options = [24, 36, 48, 60]

const CarCalculator = ({ data }) => {
	//popup state
	const [showPopup, setShowPopup] = useState(false)
	const [messageValue, setMessageValue] = useState(false)

	//calculator states
	const [calcAvailable, setCalcAvailable] = useState(false)

	const [financingReciver, setFinancingReciver] = useState(null)
	const [type, setType] = useState(null)

	const [time, setTime] = useState(null)
	const [availableTime, setAvailableTime] = useState([])

	const [payment, setPayment] = useState(null)
	const [availablePayment, setAvailablePayment] = useState([])

	const [kilometers, setKilometers] = useState(null)
	const [availableKilometers, setAvailableKilometers] = useState([])

	const [buyout, setBuyout] = useState(null)
	const [availableBuyout, setAvailableBuyout] = useState([])

	const [resultLoading, setResultLoading] = useState({
		pending: false,
		success: false,
		error: false
	})
	const [resultPrice, setResultPrice] = useState(0)

	// checks if data is correct
	// if car is from vehis, no need to check - its calculated on request
	const isCalcDataCorrect = (carData) => {
		let check = true
		if (carData.source !== 'vehis') {
			if (carData.financingOptionsPerson === '[]' && carData.financingOptionsCompany === '[]') {
				check = false
			}
			if (
				(carData.calculateWynajem === '' ||
					carData.calculateWynajem === null ||
					carData.calculateWynajem === '[]') &&
				(carData.calculateLeasing === '' ||
					carData.calculateLeasing === null ||
					carData.calculateLeasing === '[]')
			) {
				check = false
			}
		}
		return check
	}

	const getCalculateData = () => {
		let calculateData = null
		if (type === 'Wynajem' && data.car.car.calculateWynajem) {
			calculateData = JSON.parse(data.car.car.calculateWynajem)
		}
		if (type === 'Leasing' && data.car.car.calculateLeasing) {
			calculateData = JSON.parse(data.car.car.calculateLeasing)
		}

		return calculateData
	}

	//on load change type and check if all data is present
	useEffect(() => {
		if (data !== undefined) {
			const check = isCalcDataCorrect(data.car.car)
			setCalcAvailable(check)

			//set default calc values - finansingReceiver (Firma/Osoba prywatna) and type (Leasing/Wynajem)
			if (check) {
				// osoba prywatna / firma
				let receiver = financingReciver
				if (!receiver) {
					if (JSON.parse(data.car.car.financingOptionsCompany).length !== 0) {
						setFinancingReciver('company')
						receiver = 'company'
					} else {
						setFinancingReciver('person')
						receiver = 'person'
					}
				}

				// leasing / wynajem
				if (receiver === 'company') {
					setType(JSON.parse(data.car.car.financingOptionsCompany)[0])
				} else {
					setType(JSON.parse(data.car.car.financingOptionsPerson)[0])
				}
			} else {
				// no calc data available - set default message in contact form

				let message = `Dzień dobry, wysyłam zapytanie o pojazd: ${data.car.car.name}.`

				setMessageValue(message)
			}
		}
	}, [data])

	//on change type change available parameters
	useEffect(() => {
		const calculateData = getCalculateData()
		if (calculateData) {
			let currAvailableTime = []
			let currAvailableKilometers = []
			let currAvailableBuyout = []
			let currAvailablePayment = []
			calculateData?.forEach((el) => {
				!currAvailableTime.includes(el.month.toString()) &&
					currAvailableTime.push(el.month.toString())
				el.percent &&
					!currAvailablePayment.includes(el.percent.toString()) &&
					currAvailablePayment.push(el.percent.toString())
				if (type === 'Wynajem') {
					!currAvailableKilometers.includes(el.distance.toString()) &&
						currAvailableKilometers.push(el.distance.toString())
				}
				if (type === 'Leasing') {
					!currAvailableBuyout.includes(el.buyout.toString()) &&
						currAvailableBuyout.push(el.buyout.toString())
				}
			})
			setAvailableTime(currAvailableTime)
			if (!currAvailableTime.includes(time)) setTime(currAvailableTime.at(-1))

			setAvailablePayment(currAvailablePayment)
			if (!currAvailablePayment.includes(payment)) setPayment(currAvailablePayment.at(-1))

			if (type === 'Wynajem') {
				setAvailableKilometers(currAvailableKilometers)
				if (!currAvailableKilometers.includes(kilometers)) setKilometers(currAvailableKilometers[0])
			}

			if (type === 'Leasing') {
				setAvailableBuyout(currAvailableBuyout)
				if (!currAvailableBuyout.includes(buyout)) setBuyout(currAvailableBuyout.at(-1))
			}
		}
	}, [type])

	//on change payment|kilometers|time update other available
	useEffect(() => {
		if (time) {
			const calculateData = getCalculateData()

			let currAvailableKilometers = []
			let currAvailablePayment = ['0']
			let currAvailableBuyout = []
			calculateData?.forEach((el) => {
				if (el.month.toString() === time) {
					el.percent &&
						!currAvailablePayment.includes(el.percent.toString()) &&
						currAvailablePayment.push(el.percent.toString())

					if (type === 'Wynajem') {
						!currAvailableKilometers.includes(el.distance.toString()) &&
							currAvailableKilometers.push(el.distance.toString())
					}

					if (type === 'Leasing') {
						!currAvailableBuyout.includes(el.buyout.toString()) &&
							currAvailableBuyout.push(el.buyout.toString())
					}
				}
			})
			setAvailablePayment(currAvailablePayment)
			if (!currAvailablePayment.includes(payment)) setPayment(currAvailablePayment[0])

			if (type === 'Wynajem') {
				setAvailableKilometers(currAvailableKilometers)
				if (!currAvailableKilometers.includes(kilometers)) setKilometers(currAvailableKilometers[0])
			}

			if (type === 'Leasing') {
				setAvailableBuyout(currAvailableBuyout)
				if (!currAvailableBuyout.includes(buyout)) setBuyout(currAvailableBuyout[0])
			}
		}
	}, [time])

	useEffect(() => {
		if (payment) {
			const calculateData = getCalculateData()

			let currAvailableKilometers = []
			let currAvailableTime = []
			let currAvailableBuyout = []
			calculateData?.forEach((el) => {
				//|| payment === '0' <-- fix for starfleet
				if (data.car.car.source === 'starfleet') {
					if (payment === '0' && el.month.toString() === time) {
						// !currAvailableTime.includes(el.month.toString()) && currAvailableTime.push(el.month.toString());

						if (type === 'Wynajem') {
							!currAvailableKilometers.includes(el.distance.toString()) &&
								currAvailableKilometers.push(el.distance.toString())
						}

						if (type === 'Leasing') {
							!currAvailableBuyout.includes(el.buyout.toString()) &&
								currAvailableBuyout.push(el.buyout.toString())
						}
					}
				}

				if (el.percent?.toString() === payment && el.month.toString() === time) {
					// !currAvailableTime.includes(el.month.toString()) && currAvailableTime.push(el.month.toString());

					if (type === 'Wynajem') {
						!currAvailableKilometers.includes(el.distance.toString()) &&
							currAvailableKilometers.push(el.distance.toString())
					}

					if (type === 'Leasing') {
						!currAvailableBuyout.includes(el.buyout.toString()) &&
							currAvailableBuyout.push(el.buyout.toString())
					}
				}
			})
			// setAvailableTime(currAvailableTime);
			// if(!currAvailableTime.includes(time)) setTime(currAvailableTime[0]);

			if (type === 'Wynajem') {
				setAvailableKilometers(currAvailableKilometers)
				if (!currAvailableKilometers.includes(kilometers)) setKilometers(currAvailableKilometers[0])
			}

			if (type === 'Leasing') {
				setAvailableBuyout(currAvailableBuyout)
				if (!currAvailableBuyout.includes(buyout)) setBuyout(currAvailableBuyout[0])
			}
		}
	}, [payment])

	useEffect(() => {
		if (kilometers) {
			const calculateData = getCalculateData()

			let currAvailablePayment = ['0']
			let currAvailableTime = []
			calculateData?.forEach((el) => {
				if (el.distance.toString() === kilometers) {
					!currAvailableTime.includes(el.month.toString()) &&
						currAvailableTime.push(el.month.toString())
					el.percent &&
						!currAvailablePayment.includes(el.percent.toString()) &&
						currAvailablePayment.push(el.percent.toString())
				}
			})
			setAvailablePayment(currAvailablePayment)
			if (!currAvailablePayment.includes(payment)) setPayment(currAvailablePayment[0])
			setAvailableTime(currAvailableTime)
			if (!currAvailableTime.includes(time)) setTime(currAvailableTime[0])
		}
	}, [kilometers])

	//on change anything -> calculate and update message in contact form
	useEffect(() => {
		;(async () => {
			if (data.car.car.source !== 'vehis') {
				const calculateData = getCalculateData()

				calculateData?.forEach((el) => {
					if (type === 'Wynajem') {
						if (
							el.month.toString() === time &&
							((!el?.percent && payment === '0') || el.percent?.toString() === payment) &&
							el.distance.toString() === kilometers
						) {
							let finalPrice = el.installment
							if (financingReciver === 'person') finalPrice *= 1.23
							setResultPrice(Math.ceil(finalPrice))
						}
					}

					if (type === 'Leasing') {
						if (
							el.month.toString() === time &&
							((!el?.percent && payment === '0') || el.percent.toString() === payment) &&
							el.buyout.toString() === buyout
						) {
							let finalPrice = el.installment
							if (financingReciver === 'person') finalPrice *= 1.23
							setResultPrice(Math.ceil(finalPrice))
						}
					}
				})
			} else if (time !== null && payment !== null && buyout !== null) {
				//if vehis async request to calculate
				setResultLoading({ pending: true, success: false, error: false })

				try {
					const res = await axios.post(
						'https://wp.clicklease.pl/wp-json/clicklease/vehis-leasing-calculation/',
						{
							calculationValue: parseInt(data.car.car.price),
							initialFeePercentage: parseInt(payment),
							contractLength: parseInt(time),
							buyout: parseInt(buyout)
						}
					)

					setResultPrice(res.data.data)

					setResultLoading({ pending: false, success: true, error: false })
				} catch (error) {
					console.error('res error', error)
					setResultLoading({ pending: false, success: false, error: true })
				}
			}

			//update form message field
			let message = `Dzień dobry, wysyłam zapytanie o pojazd: ${data.car.car.name}.`
			if (calcAvailable) {
				message = `Dzień dobry, wysyłam zapytanie o pojazd: ${data.car.car.name}
            Osoba prywatna/firma: ${financingReciver === 'company' ? 'Firma' : 'Osoba prywatna'}
            Forma finansowania: ${type}
            Okres finansowania: ${time}msc
            Wpłata własna: ${payment}%
            ${type === 'Wynajem' ? `Limit kilometrów: ${kilometers}km` : ''}
            ${type === 'Leasing' ? `Wartość wykupu: ${buyout}%` : ''}
            Miesięczna rata ${financingReciver === 'company' ? `netto` : `brutto`}: ${resultPrice}zł
            `
			}

			setMessageValue(message)
		})()
	}, [time, payment, kilometers, resultPrice, financingReciver, buyout])

	return (
		<section
			className={`${s.calculator_section} ${s_wrapper.apply_padding} ${s_wrapper.apply_height}`}
		>
			<CarCalculatorPopupCTA
				showPopup={showPopup}
				setShowPopup={setShowPopup}
				messageValue={messageValue}
			/>
			<div className={s.calculator}>
				<h2 className={`${s_text.h1}`}>Oblicz ratę</h2>

				{calcAvailable ? (
					<>
						{/* osoba prywatna / firma */}
						<div className={s.field}>
							<HorizontalScrollWrapper classes={s.options_wrapper}>
								{data.car.car.financingOptionsCompany !== '[]' ? (
									<button
										className={`${s_btns.btn_outline_white} ${
											financingReciver === 'company' && s_btns.selected
										}`}
										onClick={() => setFinancingReciver('company')}
									>
										Firma
									</button>
								) : (
									<button className={`${s_btns.btn_outline_white} ${s_btns.btn_hidden}`}>
										Firma
									</button>
								)}
								{data.car.car.financingOptionsPerson !== '[]' ? (
									<button
										className={`${s_btns.btn_outline_white} ${
											financingReciver === 'person' && s_btns.selected
										}`}
										onClick={() => setFinancingReciver('person')}
									>
										Osoba&nbsp;prywatna
									</button>
								) : (
									<button className={`${s_btns.btn_outline_white} ${s_btns.btn_hidden}`}>
										Osoba&nbsp;prywatna
									</button>
								)}
							</HorizontalScrollWrapper>
						</div>

						{/* forma finansowania */}
						<div className={s.field}>
							<h3>Forma finansowania</h3>

							<HorizontalScrollWrapper classes={s.options_wrapper}>
								<button
									className={`${s_btns.btn_outline_white} ${type === 'Leasing' && s_btns.selected}
                                            ${
																							financingReciver === 'person'
																								? data.car.car.financingOptionsPerson &&
																									!JSON.parse(
																										data.car.car.financingOptionsPerson
																									).includes('Leasing') &&
																									s_btns.btn_hidden
																								: data.car.car.financingOptionsCompany &&
																									!JSON.parse(
																										data.car.car.financingOptionsCompany
																									).includes('Leasing') &&
																									s_btns.btn_hidden
																						}
                                        `}
									onClick={() => {
										financingReciver === 'person'
											? data.car.car.financingOptionsPerson &&
												JSON.parse(data.car.car.financingOptionsPerson).includes('Leasing') &&
												setType('Leasing')
											: data.car.car.financingOptionsCompany &&
												JSON.parse(data.car.car.financingOptionsCompany).includes('Leasing') &&
												setType('Leasing')
									}}
								>
									Leasing
								</button>

								<button
									className={`${s_btns.btn_outline_white} ${type === 'Wynajem' && s_btns.selected}
                                            ${
																							financingReciver === 'person'
																								? data.car.car.financingOptionsPerson &&
																									!JSON.parse(
																										data.car.car.financingOptionsPerson
																									).includes('Wynajem') &&
																									s_btns.btn_hidden
																								: data.car.car.financingOptionsCompany &&
																									!JSON.parse(
																										data.car.car.financingOptionsCompany
																									).includes('Wynajem') &&
																									s_btns.btn_hidden
																						}
                                        `}
									onClick={() => {
										financingReciver === 'person'
											? data.car.car.financingOptionsPerson &&
												JSON.parse(data.car.car.financingOptionsPerson).includes('Wynajem') &&
												setType('Wynajem')
											: data.car.car.financingOptionsCompany &&
												JSON.parse(data.car.car.financingOptionsCompany).includes('Wynajem') &&
												setType('Wynajem')
									}}
								>
									Wynajem
								</button>
							</HorizontalScrollWrapper>
						</div>

						{/* okres finansowania */}
						<div className={s.field}>
							<h3>Okres finansowania</h3>
							<HorizontalScrollWrapper classes={s.options_wrapper}>
								{financing_period_options.map((el, id) => (
									<button
										key={id}
										className={`${s_btns.btn_outline_white} ${
											time === el.toString() && s_btns.selected
										}
                                                ${
																									!availableTime.includes(el.toString()) &&
																									s_btns.btn_hidden
																								}
                                            `}
										onClick={() => {
											availableTime.includes(el.toString()) && setTime(el.toString())
										}}
									>
										{el}&nbsp;msc
									</button>
								))}
							</HorizontalScrollWrapper>
						</div>

						{/* wpłata własna */}
						<div className={s.field}>
							<h3>Wpłata własna</h3>

							<HorizontalScrollWrapper classes={s.options_wrapper}>
								{/* vehis */}
								{data.car.car.source === 'vehis' &&
									initial_payments_vehis.map((el, id) => (
										<button
											key={id}
											className={`${s_btns.btn_outline_white} ${
												payment?.toString() === el.toString() && s_btns.selected
											}
                                                ${
																									!availablePayment.includes(el.toString()) &&
																									s_btns.btn_hidden
																								}
                                            `}
											onClick={() => {
												availablePayment.includes(el.toString()) && setPayment(el.toString())
											}}
										>
											{el}&nbsp;%
										</button>
									))}

								{/* starfleet */}
								{data.car.car.source === 'starfleet' &&
									initial_payments_starfleet.map((el, id) => (
										<button
											key={id}
											className={`${s_btns.btn_outline_white} ${
												payment?.toString() === el.toString() && s_btns.selected
											}
                                                ${
																									!availablePayment.includes(el.toString()) &&
																									s_btns.btn_hidden
																								}
                                            `}
											onClick={() => {
												availablePayment.includes(el.toString()) && setPayment(el.toString())
											}}
										>
											{el}%
										</button>
									))}
							</HorizontalScrollWrapper>
						</div>

						{/* limit kilometrów - tylko dla wynajmu */}
						{type === 'Wynajem' && (
							<div className={s.field}>
								<h3>Limit kilometrów</h3>

								<HorizontalScrollWrapper classes={s.options_wrapper}>
									{kilometers_limits.map((el, id) => (
										<button
											key={id}
											className={`${s_btns.btn_outline_white} ${
												kilometers === el.toString() && s_btns.selected
											}
                          ${!availableKilometers.includes(el.toString()) && s_btns.btn_hidden}
                      `}
											onClick={() => {
												availableKilometers.includes(el.toString()) && setKilometers(el.toString())
											}}
										>
											{el}&nbsp;km
										</button>
									))}
								</HorizontalScrollWrapper>
							</div>
						)}

						{/* wartość wykupu - tylko dla leasingu */}
						{type === 'Leasing' && (
							<div className={s.field}>
								<h3>Wartość wykupu</h3>

								<HorizontalScrollWrapper classes={s.options_wrapper}>
									{availableBuyout.map((el, id) => (
										<button
											key={id}
											className={`${s_btns.btn_outline_white} ${
												buyout === el.toString() && s_btns.selected
											}`}
											onClick={() => setBuyout(el.toString())}
										>
											{el}&nbsp;%
										</button>
									))}
								</HorizontalScrollWrapper>
							</div>
						)}

						{data.car.car.services && JSON.parse(data.car.car.services).length > 0 && (
							<div className={s.field}>
								<h3>Usługi w pakiecie:</h3>

								<HorizontalScrollWrapper
									classes={`${s.options_wrapper} ${s_text.checkmark_circle_list}`}
									wrapper_ul={true}
								>
									{data.car.car.services &&
										JSON.parse(data.car.car.services).map((el, id) => <li key={id}>{el}</li>)}
								</HorizontalScrollWrapper>
							</div>
						)}

						<hr />

						<div className={s.result}>
							{data.car.car.source !== 'vehis' ? (
								<div className={s.price}>{resultPrice}&nbsp;zł</div>
							) : (
								<>
									{resultLoading.pending ? (
										<div className={s.loading}>Obliczanie...</div>
									) : (
										<>
											{resultLoading.error && <div className={s.error}>Wystąpił błąd</div>}
											{resultLoading.success && (
												<div className={s.price}>{resultPrice}&nbsp;zł</div>
											)}
										</>
									)}
								</>
							)}
							<div className={s.info}>
								Twoja miesięczna rata {financingReciver === 'company' ? 'netto' : 'brutto'}
							</div>
						</div>

						<div className={s.result_text}>
							Wpłata własna:{' '}
							<span>
								{payment === '0'
									? 0
									: financingReciver === 'company'
										? Math.ceil(data.car.car.price * (payment / 100))
										: Math.ceil(data.car.car.price * (payment / 100) * 1.23)}{' '}
								zł {financingReciver === 'company' ? 'netto' : 'brutto'}
							</span>
						</div>

						<div className={s.result_text}>
							Cena katalogowa:{' '}
							<span>
								{financingReciver === 'company'
									? data.car.car.price
									: Math.ceil(data.car.car.price * 1.23)}{' '}
								zł {financingReciver === 'company' ? 'netto' : 'brutto'}
							</span>
						</div>
					</>
				) : (
					<>
						<div className={s.no_data}>
							W celu uzyskania oferty prosimy o kontakt przez formularz kontaktowy.
						</div>
					</>
				)}

				<button className={`${s.cta} ${s_btns.btn_full}`} onClick={() => setShowPopup(true)}>
					Zapytaj o pojazd
				</button>
			</div>
		</section>
	)
}

export default CarCalculator
