import React, { useState, useRef } from 'react';
import {useQuery, gql, ApolloClient, InMemoryCache} from '@apollo/client';
import Slick from 'react-slick';
import CarCalculator from '@components/car-calculator/car-calculator';

import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import HandleLoading from '@hoc/handle-loading/handle-loading';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';

import * as s from './single-vehicle.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import '@assets/styles/slider_vehicle.scss';
import { useEffect } from 'react';

const VEHICLE_QUERY = gql`
    query VehicleQuery($slug: ID!) {
        car(id: $slug, idType: SLUG) {
            car{
                name
                engineCapacity
                brand
                drive
                enginePower
                images
                gearboxType
                fuelType
                mainImg
                model
                state
                typeOfCar
                bodyType
                doorsNumber
                seatsNumber
                year
                price
                description
                financingOptionsPerson
                financingOptionsCompany
                services
                calculateWynajem
                calculateLeasing
                source
            }
        }
    }
`;

const generalClient = new ApolloClient({
	uri: 'https://wp.clicklease.pl/graphql',
	cache: new InMemoryCache()
});

const SingleVehicle = ({params}) => {

    const mainImg = useRef(null);
    const slick = useRef(null);

    const { loading, error, data } = useQuery(VEHICLE_QUERY, {
        variables: {slug: params['single-vehicle']},
	    client: generalClient
    });

    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    vertical: false,
                    verticalSwiping: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    vertical: false,
                    verticalSwiping: false
                }
            }
        ],
        afterChange: current => {
            const el = document.querySelector(`.car_section [data-index='${current}'] img`);
            changeImgUrl(el.src);
        }
    }

    const changeImgUrl = (url) => {
        mainImg.current.style.opacity = 0;
        setTimeout(() => {
            mainImg.current.src = url;
        }, 220);
        setTimeout(() => {
            mainImg.current.style.opacity = 1;
        }, 300);
    }


    //fold equipment
    const [equipmentFold, setEquipmentFold] = useState(true);
    const [equipmentFoldActive, setEquipmentFoldActive] = useState(true);

    //do not fold if not enough items
    useEffect(() => {

        if(data?.car.car.description !== null){
            const amount = data?.car.car.description?.split('|').length;

            if(typeof window !== 'undefined'){
                if(amount < 24 && window.innerWidth >= 768){
                    setEquipmentFoldActive(false);
                } else if(amount < 10) {
                    setEquipmentFoldActive(false);
                }
            }
        } else {
            setEquipmentFoldActive(false);
        }

    }, [data]);

    return (
        <MainLayout>

			<BreadCrumbs />

            <section className={`${s.car_section} car_section ${s_wrapper.apply}`}>
                <HandleLoading loading={loading} error={error}>

                    <Seo
                        title={data?.car.car.name}
                        ogImageUrl={data?.car.car.mainImg}
                        description={'Oferta finansowania pojazdu ClickLease.pl. Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych.'}
                    />

                    <h1>{data?.car.car.name}</h1>

                    {data?.car.car.source === 'vehis' &&
                        <div className={s.more_options}>
                            Model dostępny w wielu wersjach! Skontaktuj się z nami po więcej informacji.
                        </div>
                    }

                    <div className={s.img_wrapper}>
                        <img ref={mainImg} src={data?.car.car.mainImg} alt="Zdjęcie pojazdu" className={s.curr_img} />

                        <Slick {...settings} className={s.slider_wrapper} ref={slick}>

                            <div className={s.slide_outer}>
                                <div className={s.slide} onClick={() => slick.current.slickGoTo(0)}>
                                    <img src={data?.car.car.mainImg} alt='Zdjęcie pojazdu' />
                                </div>
                            </div>

                            {data?.car.car.images && JSON.parse(data?.car.car.images).map((el, id) => (
                                <div key={id} className={s.slide_outer}>
                                    <div className={s.slide} onClick={() => slick.current.slickGoTo(id+1)}>
                                        <img src={el} alt='Zdjęcie pojazdu' />
                                    </div>
                                </div>
                            ))}

                        </Slick>

                    </div>

                    <hr />

                    <div className={s.technical_data}>
                        <h3>Dane techniczne</h3>

                        <ul>
                            <li>
                                Marka:
                                <span>{data?.car.car.brand}</span>
                            </li>
                            <li>
                                Model:
                                <span>{data?.car.car.model}</span>
                            </li>
                            <li>
                                Rodzaj:
                                <span>{data?.car.car.typeOfCar}</span>
                            </li>
                            {data?.car.car.state &&
                                <li>
                                    Stan:
                                    <span>{data?.car.car.state}</span>
                                </li>
                            }
                            <li>
                                Paliwo:
                                <span>{data?.car.car.fuelType}</span>
                            </li>
                            <li>
                                Pojemność skokowa:
                                <span>{data?.car.car.engineCapacity}</span>
                            </li>
                            <li>
                                Skrzynia biegów:
                                <span>{data?.car.car.gearboxType}</span>
                            </li>
                            <li>
                                Rok produkcji:
                                <span>{data?.car.car.year}</span>
                            </li>
                            <li>
                                Typ nadwozia:
                                <span>{data?.car.car.bodyType}</span>
                            </li>
                            <li>
                                Ilość drzwi:
                                <span>{data?.car.car.doorsNumber}</span>
                            </li>
                            <li>
                                Ilość miejsc:
                                <span>{data?.car.car.seatsNumber}</span>
                            </li>
                            <li>
                                Moc:
                                <span>{data?.car.car.enginePower}</span>
                            </li>
                            <li>
                                Typ napędu:
                                <span>{data?.car.car.drive}</span>
                            </li>
                        </ul>
                    </div>

                    <hr />

                    <div className={s.equipment_data}>
                        <h3>Wyposażenie</h3>
                        <div className={`${s.list_wrapper} ${equipmentFold && equipmentFoldActive && s.list_fold}`}>
                            <ul className={`${s_text.checkmark_circle_list} ${data?.car.car.description?.split('|').length <= 3 && s.ul_flex}`}>
                                {
                                    data?.car.car.description?.split('|').map((el, id) => (
                                        <li key={id}>
                                            {el}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        {equipmentFoldActive &&
                            <button className={`${s.show_more} ${s_btns.btn_full} ${s_btns.btn_arrow}`} onClick={() => setEquipmentFold(prev => !prev)}>Pokaż {equipmentFold ? 'więcej' : 'mniej'}</button>
                        }

                    </div>

                </HandleLoading>
            </section>

            {data && !error && !loading && (<CarCalculator data={data} />)}

            <ContactSection />

            <MapSection />

        </MainLayout>
    )
};

export default SingleVehicle;
